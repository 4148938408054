<template>
    <v-container fluid>
        <v-navigation-drawer v-model="drawer" dark absolute temporary style="z-index: 99999;" class="menu-d">
            <v-list-item>
                <img src="../assets/logo-dd.png"
                    style="width: 60%;display: block;margin: 0 auto;margin-top: 10px" alt="Beeasy Logo">
            </v-list-item>
            <v-list-item>
                <!-- <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
            </v-list-item-avatar> -->

                <v-list-item-content>
                    <v-list-item-title style="text-align: center;">{{ userData.fullName }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item v-for="item in items" :key="item.title" link @click="goTo(item)"
                    :style="'background-color: ' + (item.isActive ? '#00AF7D' : 'transparent') + ';'">
                    <v-list-item-action>
                        <img :src="item.icon" style="filter: invert(1);width: 25px;" alt="">
                    </v-list-item-action>

                    <v-list-item-content>

                        <v-list-item-title v-if="item.name === 'domain'" style="margin-top: -15px">
                            <v-badge color="green" content="Novo" inline>
                                {{ item.title }}
                            </v-badge>
                        </v-list-item-title>
                        <v-list-item-title v-else>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <br>
                <br>
                <v-list-item link @click="logout()">
                    <v-list-item-action>
                        <img :src="require(`../assets/icons/sidebar/v2/Saída.svg`)"
                            style="filter: invert(1);width: 25px;" alt="">
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>Encerrar Sessão</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>
        <!-- Navbar para usuários que não são administradores -->
        <v-row class="navbar" v-if="userData.role != 'admin'">
            <v-col cols="1" v-if="$store.getters.isMobile">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer; scrollToTop()"
                    style="margin-top: .9vh;"></v-app-bar-nav-icon>
            </v-col>
            <!-- Link para "Ver minha loja" -->
            <v-col class="show-store-mobile" v-if="$store.getters.isMobile">
                <a :href="urlStore" target="_blank">
                    <img src="../assets/icons/sidebar/v2/Site.svg" alt="">
                </a>
            </v-col>
            <v-col class="show-store" v-if="!$store.getters.isMobile">
            </v-col>
            <!-- Informações sobre "A Liberar" -->
        
            <!-- Imagem do perfil -->
            <v-col cols="2" md="1" class="profile" v-if="!$store.getters.isMobile">
                <img src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp" alt="">
            </v-col>
        </v-row>

        <!-- Navbar para administradores -->
        <v-row class="navbar" v-if="userData.role == 'admin'">
            <v-col cols="1" v-if="$store.getters.isMobile">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer; scrollToTop()"
                    style="margin-top: .9vh;"></v-app-bar-nav-icon>
            </v-col>
            <v-col class="show-store"></v-col>
            <v-col cols="3" md="1" class="to-release"></v-col>
            <v-col cols="3" md="1" class="balance"></v-col>
            <v-col cols="2" md="1" class="profile" v-if="!$store.getters.isMobile">
                <img src="https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp" alt="">
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import config from '@/store/config'
// Função para verificar se o slug contém um ponto (.)
function testDomain(domain) {
    return domain.indexOf('.') > -1;
}

export default {
    name: 'Navbar',
    data() {
        return {
            userData: {}, // Dados do usuário
            storeData: {}, // Dados da loja
            walletData: {}, // Dados da carteira
            urlStore: '', // URL da loja
            drawer: null,
            isActive: 'home',
            node_env: process.env.NODE_ENV, // Ambiente de execução (development ou production)
            items: [],
        }
    },
    async mounted() {
        // Obter dados do usuário, da loja e da carteira do Vuex store
        this.userData = this.$store.getters.getUserData;
        this.storeData = this.$store.getters.getStoreData;
        this.walletData = this.$store.getters.getWalletData;
        // Inicializar a URL da loja
        await this.init();
        await this.setMenu()
        await this.activeMenu()
    },
    methods: {
        // Método para inicializar a URL da loja
        init() {
            if(this.userData.role != 'admin'){
                if (this.node_env === 'development') {
                    // Ambiente de desenvolvimento
                    this.urlStore = `https://teste.${config.url}/${this.$store.state.storeData.slug}`;
                } else {
                    // Ambiente de produção
                    if (testDomain(this.$store.state.storeData.slug)) {
                        // Se o slug contém um ponto, usar como subdomínio
                        this.urlStore = `https://${this.$store.state.storeData.slug}`;
                    } else {
                        // Caso contrário, usar como parte do caminho
                        this.urlStore = `https://${config.url}/${this.$store.state.storeData.slug}`;
                    }
                }
            }
            
        },
        async setMenu() {
            if (this.userData.role === 'ADM') {
                this.items = [
                    { name: 'adm/users', icon: require(`@/assets/icons/sidebar/v2/Dashboard.svg`), title: 'Usuários', active: false },
                ]
            } else {
                this.items = [
                    { name: 'orders', icon: require(`@/assets/icons/sidebar/v2/Pedidos.svg`), title: 'Pedidos', isActive: false },
                ]
            }
        },
        async activeMenu() {
            this.items.forEach(m => {
                if (m.name === this.$route.path.split('/')[1]) {
                    m.isActive = true
                    this.isActive = m.name
                } else {
                    m.isActive = false
                }
            })
        },
        goTo(e) {
            if (e.name === '') {
                return
            }
            if (this.$route.path != `/${e.name}`) {
                this.$router.push(`/${e.name}`)
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        async logout() {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            if (this.$route.path != '/login') {
                this.$router.push('/login')
            }
        },

    },
    watch: {
        async $route() {
            await this.activeMenu()
        }
    }
}
</script>

<style scoped>
/* Estilos padrão */
* {
    font-family: "Questrial", sans-serif;
}

/* Estilos da navbar */
.navbar {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    background-color: #fff;
    color: #fff;
    border-bottom: 2px solid #d6d6d6;
}

/* Estilos da imagem de perfil */
.navbar .profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    float: right;
    margin-right: 1rem;
    margin-top: .35rem;
    border: 2px solid #00AF7D;
    cursor: pointer;
}

/* Estilos do texto "A Liberar" */
.navbar .to-release span {
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #2b3b54;
}

.navbar .to-release p {
    margin: 0;
    color: #ef4f4f;
    font-size: 1rem;
    font-weight: 600;
}

/* Estilos do texto "Disponível" */
.navbar .balance span {
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #2b3b54;
}

.navbar .balance p {
    margin: 0;
    color: #00AF7D;
    font-size: 1rem;
    font-weight: 600;
}

/* Estilos do link "Ver minha loja" */
.show-store {
    text-align: right;
    padding-top: 1.6rem;
    padding-right: 2rem;
}

.show-store a {
    text-decoration: none;
    color: #00AF7D;
    font-family: Outfit, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    outline: none;
    background-color: transparent;
    letter-spacing: 1px;
    transition: .2s ease-in-out;
}







.show-store-mobile {
    text-align: right;
    /* padding-right: 2rem; */
}

.show-store-mobile a {
    text-decoration: none;
    color: #00AF7D;
    font-family: Outfit, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    outline: none;
    background-color: transparent;
    letter-spacing: 1px;
    transition: .2s ease-in-out;
}

.show-store-mobile img {
    width: 36px;
    display: block;
    float: right;
    margin-top: .8vh;
}

.menu-d {
    background-color: #2b3b54 !important;
}
</style>
