<template>
    <div class="fill">
      <div class="loading-modal">
        <img src="@/assets/loading.gif" class="loading-img" alt="">
      </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
}
</script>
<style>
.fill{
    background-color: #2b3b54f1;
    z-index: 99999999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loading-modal img{
    display: block;
    margin: 0 auto;
    margin-top: 22vh;
    width: 20%;
}

@media (max-width: 768px) {
  .loading-modal img{
    display: block;
    margin: 0 auto;
    margin-top: 22vh;
    width: 50%;
}
}

</style>