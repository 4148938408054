<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2>Usuários</h2>
          </v-col>
          <v-col>
            <label for="" style="opacity: 0">-</label>
            <button class="button-default" @click="pepareInsertNewUser">Novo Usuário</button>
          </v-col>
          
          <v-col cols=12>
            <v-data-table :headers="headers" :items="users" :items-per-page="100000" class="elevation-1">

              <template v-slot:[`item.brand_url`]="{ item }">
                <a :href="item.brand_url" target="blank">{{item.brand_url}}</a>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <button class="button-default-2" @click="prepareEditUser(item)">Editar</button>&nbsp;&nbsp;
                <a :href="`https://pedistore.com.br/${item.catalogue_code}`" class="button-default" target="blank">Ver</a>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="mNewUser" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Novo Usuário</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <div class="form-item">
              <label>Nome</label>
              <input type="text" class="input-default" v-model="userDataInsert.full_name" required>
            </div>
            <div class="form-item">
              <label>Email</label>
              <input type="email" class="input-default" v-model="userDataInsert.email" required>
            </div>
            <div class="form-item">
              <label>Senha</label>
              <input type="password" class="input-default" v-model="userDataInsert.password" required>
            </div>
            <div class="form-item">
              <label>Contrato</label>
              <input type="text" class="input-default" v-model="userDataInsert.client_code" required>
            </div>
            <div class="form-item">
              <label>Código Usuário</label>
              <input type="text" class="input-default" v-model="userDataInsert.brand_code" required>
            </div>
            <div class="form-item">
              <label>Site Principal</label>
              <input type="text" class="input-default" v-model="userDataInsert.brand_url" required>
            </div>
            <div class="form-item">
              <label>Catálogo</label>
              <input type="text" class="input-default" v-model="userDataInsert.catalogue_code" required>
            </div>
          </v-form>
          
        </v-card-text>
        <v-card-actions>
          <button class="button-link" @click="mNewUser = false">
            Cancelar
          </button>
          <button class="button-default" @click="insertUser" v-if="!loading">
            Salvar
          </button>
          <button class="button-default" v-if="loading">
            Aguarde...
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="mEditUser" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar Usuário</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <div class="form-item">
              <label>Nome</label>
              <input type="text" class="input-default" v-model="userDataInsert.full_name" required>
            </div>
            <div class="form-item">
              <label>Email</label>
              <input type="email" class="input-default" v-model="userDataInsert.email" required>
            </div>
            <div class="form-item">
              <label>Contrato</label>
              <input type="text" class="input-default" v-model="userDataInsert.client_code" required>
            </div>
            <div class="form-item">
              <label>Código Usuário</label>
              <input type="text" class="input-default" v-model="userDataInsert.brand_code" required>
            </div>
            <div class="form-item">
              <label>Site Principal</label>
              <input type="text" class="input-default" v-model="userDataInsert.brand_url" required>
            </div>
            <div class="form-item">
              <label>Catálogo</label>
              <input type="text" class="input-default" v-model="userDataInsert.catalogue_code" required>
            </div>
            <div class="form-item">
              <label>Precisa de Senha?</label>
              {{ userDataInsert.need_password }}
              <select type="text" class="input-default" v-model="userDataInsert.need_password" required>
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </select>
            </div>
            <div class="form-item" v-if="userDataInsert.need_password">
              <label>Senha Inicial</label>
              <input type="password" class="input-default" v-model="userDataInsert.password_init" required>
            </div>
          </v-form>
          
        </v-card-text>
        <v-card-actions>
          <button class="button-link" @click="cancelEdit">
            Cancelar
          </button>
          <button class="button-default" @click="updateUser" v-if="!loading">
            Salvar
          </button>
          <button class="button-default" v-if="loading">
            Aguarde...
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Loading v-if="loadingPage" />
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})


export default {
  name: 'AdmUsersPage',
  metaInfo() {
    return {
      title: `Usuários - Divulga Digital`
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      mNewUser: false,
      mEditUser: false,
      loading: false,
      headers: [
        {
          text: 'Código Usuário',
          align: 'center',
          sortable: false,
          value: 'brand_code',
        },
        { text: 'Nome', value: 'full_name', align: 'center', },
        { text: 'Contrato', value: 'client_code', align: 'center', },
        { text: 'Catálogo', value: 'catalogue_code', align: 'center', },
        { text: 'Site Principal', value: 'brand_url', align: 'center', },
        { text: 'Data Cadastro', value: 'created_at', align: 'center', },
        { text: '#', value: 'actions', align: 'center', },
      ],
      users: [],
      userData: {},
      storeData: {},
      userDataInsert: {
        "id":           "",
        "full_name":     "",
        "email":         "",
        "password":      "",
        "client_code":   "",
        "brand_code":    "",
        "brand_url":     "",
        "catalogue_code":"",
        need_password:  0,
        password_init: "",
        "role":          "SHOP"
    },
      start: '',
      end: '',
      loadingPage: false
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      this.start = firstDay.toISOString().split('T')[0]
      this.end = lastDay.toISOString().split('T')[0]
      await this.getOrders()
      
    },
    async getOrders() {
      try {
        this.users = []
        this.loadingPage = true
        const { data } = await api.get(`/users`, await this.$store.getters.getConfig)
        this.users = data
        this.loadingPage = false
      } catch (error) {
        console.error(error)
      }
    },
    async pepareInsertNewUser() {
      this.mNewUser = true
    },
    async insertUser() {
      try {
        if(!this.userDataInsert.full_name || !this.userDataInsert.email || !this.userDataInsert.password || !this.userDataInsert.client_code || !this.userDataInsert.brand_code || !this.userDataInsert.brand_url || !this.userDataInsert.catalogue_code) {
          alert('Preencha todos os campos')
          return
        }
        this.loading = true
        await api.post(`/register`, this.userDataInsert)
        this.loading = false
        this.mNewUser = false
        await this.getOrders()
      } catch (error) {
        console.error(error)
      }
    },
    prepareEditUser(user) {
      this.userDataInsert = {
        "id":          user.id,
        "full_name":    user.full_name,
        "email":       user.email,
        "password":      "",
        "client_code":   user.client_code,
        "brand_code":    user.brand_code,
        "brand_url":     user.brand_url,
        "catalogue_code": user.catalogue_code,
        need_password:  user.need_password,
        password_init: "",
        "role":          "SHOP"
      }
      this.mEditUser = true
    },
    cancelEdit() {
      this.userDataInsert = {
          "full_name":     "",
          "email":         "",
          "password":      "",
          "client_code":   "",
          "brand_code":    "",
          "brand_url":     "",
          "catalogue_code":"",
          need_password:  0,
          password_init: "",
          "role":          "SHOP"
      }
      this.mEditUser = false
    },
    async updateUser(){
      try {
        if(!this.userDataInsert.full_name || !this.userDataInsert.email || !this.userDataInsert.client_code || !this.userDataInsert.brand_code || !this.userDataInsert.brand_url || !this.userDataInsert.catalogue_code) {
          alert('Preencha todos os campos')
          return
        }
        this.loading = true
        await api.put(`/users`, this.userDataInsert, await this.$store.getters.getConfig)
        this.loading = false
        this.mEditUser = false
        await this.getOrders()
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.button-default-2{
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.column-pos {
  text-align: center;
  color: rgb(55 65 81);
  ;
  padding: .5rem;
}

.column-title {
  font-size: 1rem;
  font-weight: 400;
}

.column-subtitle {
  font-size: .8rem;
  font-weight: 400;
}

.button-link {
  width: 100%;
  padding: .5rem;
  color: #00AF7D;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
}

.button-link:hover {
  background-color: #f2f2f2;
}
</style>
