<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
  baseURL: config.baseURL
})
export default {
  name: 'App',
  async mounted() {
    await this.verifyLogged();
  },
  data() {
    return {
      config: {}
    }
  },
  watch: {
    async $route(to, from) {
      try {
        if (!localStorage.getItem('token')) { return; }
        if (this.$route.path == '/register') { return; }
        let me = await this.me()
        if (me.id) {
          if (this.$route.path == '/login' || this.$route.path == '/') {
            this.$router.push('/home')
          }
        } else {
          if (this.$route.path != '/register') {
            this.logout()
            return
          }

        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  methods: {
    async init() {
      if (localStorage.getItem('token')) {
        console.log('init')
        this.$store.commit('update_dados', JSON.parse(localStorage.getItem('user')))
      }
    },
    async verifyLogged() {
      if (localStorage.getItem('token')) {
        this.$store.commit('update_dados', JSON.parse(localStorage.getItem('user')))
        this.config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        let me = await this.me()
        console.log(me)
        if (me.id) {
          if (this.$route.path == '/login' || this.$route.path == '/') {
            this.$router.push('/home')
          }
        } else {
          this.logout()
          return
        }
      } else {
        if (this.$route.path != '/login' && this.$route.path != '/register' && this.$route.path.indexOf('forgot') == -1) {
          this.$router.push('/login')
        }
        return
      }
    },
    async me() {
      try {
        this.config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        let response = await api.get('/me', this.config)
        
        return response.data
      } catch (error) {
        console.log(error)
        if (this.$route.path != '/register') {
          this.logout()
          return
        }
      }
    },
    async logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      if (this.$route.path != '/login') {
        this.$router.push('/login')
      }
    },
  }
};
</script>
