<template>
    <div class="card-plans">
        <v-container>
            <v-row>
                <v-col cols="12" md="4" class="plan">
                    <h2>R$ 149,90</h2><br>
                    <h3>Mensal</h3>

                    <p>
                        Seu perfil é <strong>imediatista</strong>.
                    </p>
                    <ul>
                        <li>30 dias para vender</li>
                        <li>Loja virtual própria</li>
                        <li>Dashboard individual</li>
                        <li>Carteira digital</li>
                        <li>Acesso a todos os produtos</li>
                        <li class="no"><strong>Grupo VIP</strong></li>
                        <li class="no"><strong>Presente exclusivo da marca</strong></li>
                    </ul>

                    <button class="button-subscribe" @click="selectPlan('Mensal', 149.90)">
                        Assinar
                    </button>
                </v-col>
                <v-col cols="12" md="4" class="plan">
                    <h2>R$ 119,90<small style="color: #fff">/mês</small></h2><br>
                    <h3>Trimestral</h3>

                    <p>
                        Seu perfil é <strong>conservador</strong>.
                    </p>

                    <ul>
                        <li>90 dias para vender</li>
                        <li>Loja virtual própria</li>
                        <li>Dashboard individual</li>
                        <li>Carteira digital</li>
                        <li>Acesso a todos os produtos</li>
                        <li><strong>Grupo VIP</strong></li>
                        <li class="no"><strong>Presente exclusivo da marca</strong></li>
                    </ul>

                    <button class="button-subscribe" @click="selectPlan('Trimestral', 119.90 * 3)">
                        Assinar
                    </button>
                </v-col>
                <v-col cols="12" md="4" class="plan">
                    <h2>R$ 99,90<small style="color: #fff">/mês</small></h2><br>
                    <h3>Anual</h3>

                    <p>
                        Seu perfil é de <strong>visionário</strong>.
                    </p>

                    <ul>
                        <li>365 dias para vender</li>
                        <li>Loja virtual própria</li>
                        <li>Dashboard individual</li>
                        <li>Carteira digital</li>
                        <li>Acesso a todos os produtos</li>
                        <li><strong>Grupo VIP</strong></li>
                        <li><strong>Presente exclusivo da marca</strong></li>
                    </ul>

                    <button class="button-subscribe" @click="selectPlan('Anual', 99.90 * 12)">
                        Assinar
                    </button>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    methods: {
        selectPlan(type, price) {
            this.$emit('selectPlan', { type, price })
        }
    }
}
</script>

<style scoped>

.card-plans {
  background-color: #1c1a1d;
  color: #fff;
  border-radius: 20px;
  padding: 15px;
}

.card-plans .plan {
  padding: 20px;
}

.card-plans .plan h2 {
  font-size: 2.3rem;
  font-weight: 700;
  color: #00AF7D;
}

.card-plans .plan h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  margin-top: -10px;
}

.card-plans .plan p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}

.card-plans .plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-plans .plan ul li:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('@/assets/check.svg') no-repeat 50%;
  background-size: cover;
  margin-right: 10px;
}

.card-plans .plan ul li.no:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('@/assets/uncheck.svg') no-repeat 50% !important;
  background-size: cover;
  margin-right: 10px;
}

.card-plans .plan ul li.no {
  text-decoration: line-through
}

.card-plans .plan ul li {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}

.button-subscribe {
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 25px;
  background-color: #00af7d85;
  cursor: pointer;
  margin-top: 20px;
  transition: .4s ease-in-out;
}

.button-subscribe:hover {
  background-color: #00AF7D;
  color: #fff
}
</style>