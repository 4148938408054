<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2>Pedidos</h2>
          </v-col>
          <v-col>
            <v-data-table :headers="headers" :items="orders" :page.sync="page" :items-per-page="itemsPerPage"
              hide-default-footer class="elevation-1" @page-count="pageCount = $event">
              <template v-slot:[`item.items`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">PEDIDO {{ (item.id + "").padStart(6, "0") }}</span><br>
                  <span class="column-subtitle">{{ item.produtos.reduce((acc, p) => acc + p.estoquePedido, 0) }} produto(s)</span>
                </div>
              </template>

              <template v-slot:[`item.date`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ new Date(item.created_at).toLocaleDateString() }}</span><br>
                  <span class="column-subtitle">{{ new Date(item.created_at).toLocaleTimeString() }}</span>
                </div>
              </template>


              <template v-slot:[`item.price`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ item.total.toLocaleString('pt-BR', {
                    style: 'currency', currency: 'BRL'
                  })
                    }}</span>
                </div>
              </template>

              <template v-slot:[`item.costumer`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ item.cliente.nome }}</span><br>
                  <small>{{ item.cliente.whatsapp }}</small>
                </div>
              </template>

              <!-- <template v-slot:[`item.shipping`]="{ item }">
                <div class="column-pos">
                  <span class="column-title" v-if="item.shippingStatus == 'unshipped'">
                    <v-chip class="ma-2" outlined>
                      Cancelado
                    </v-chip>
                  </span>
                  <span v-else>
                    <span class="column-title" v-if="item.shippingStatus == 'shipped'">
                      <v-chip class="ma-2" outlined color="primary">
                        Enviado
                      </v-chip><br>
                      <small>{{ item.shippingTrackingNumber }}</small>
                    </span>
                    <span class="column-title" v-else>
                      <v-chip class="ma-2" outlined color="orange">
                        Preparando
                      </v-chip>
                    </span>
                  </span>
                </div>
              </template> -->

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="#00AF7D" outlined @click="openDialog(item)">Detalhes</v-btn>&nbsp;
                <v-btn color="#00AF7D" outlined @click="openDialogToPrint(item)"><v-icon>mdi-printer</v-icon></v-btn>&nbsp;
                <v-btn color="red" outlined @click="deleteOrder(item.id)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
              <!-- <v-text-field
                    :value="itemsPerPage"
                    label="Produtos por página"
                    type="number"
                    min="-1"
                    max="15"
                    @input="itemsPerPage = parseInt($event, 10)"
                  ></v-text-field> -->
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Loading v-if="loadingPage" />

    <!-- Dialog for Order Details -->
    <v-dialog v-model="dialog" max-width="800" class="dialog-spacing" style="z-index: 9999;">
      <v-card>
        <v-card-title class="headline">Detalhes do Pedido</v-card-title>
        <v-card-text>
          <div v-if="selectedOrder">
            <div class="section-header">Venda</div>
            <div class="totals">
              <div class="total-item total-item-alt">
                <span><strong>ID</strong></span>
                <span class="spanValue"> Pedido {{ (selectedOrder.id + "").padStart(6, "0") }}</span>
              </div>
              <div class="total-item">
                <span><strong>Data</strong></span>
                <span class="spanValue"> {{ new Date(selectedOrder.created_at).toLocaleDateString() }}</span>
              </div>
              <div class="total-item total-item-alt">
                <span><strong>Cliente</strong></span>
                <span class="spanValue"> {{ selectedOrder.cliente.nome }}</span>
              </div>
              <div class="total-item">
                <span><strong>Contato</strong></span>
                <span class="spanValue"> {{ selectedOrder.cliente.whatsapp }}</span>
              </div>
              <div class="total-item total-item-alt">
                <span><strong>Total</strong></span>
                <span class="spanValue"> {{ selectedOrder.total.toLocaleString('pt-BR', {
                  style: 'currency', currency: 'BRL'
                }) }}</span>
              </div>
            </div>

            <div class="section-header">Produtos</div>
            <div class="totals">
              <div class="total-item total-item-alt">
                <v-container>
                  <v-row v-for="(p, index) in selectedOrder.produtos" :key="index">
                    <v-col cols="4" sm="4" md="4" lg="4">
                      <img style="width: 100%;" :src="p.imagem.nome" alt="">
                    </v-col>
                    <v-col>
                      <h3>{{ p.nome }}</h3>
                      <p>{{ p.estoquePedido }} unidade(s)</p>
                      Código: {{ ('000' + p.corWear).substr(-3) }}<br>
                      Tamanho: {{ p.tamanho }}<br>
                      Ref.: {{ p.referencia }}<br>
                      <v-row>
                        <v-col cols="1">
                          <div class="estampa" v-if="p.corData.estampaImagem != null">
                            <img :src="generateImageUrl(p.corData.estampaImagem)" alt="">
                          </div>
                          <div class="cor" :style="`background-color: ${p.corData.hex}`" v-if="p.corData.estampaImagem == null"></div>
                        </v-col>
                        <v-col cols="4" style="text-transform: uppercase;padding-top: 17px;">
                          <strong>{{ p.corData.nomeEstampa != null ? p.corData.nomeEstampa : p.corData.nome }} </strong>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
        
          </div>
          <div v-else>
            <p>Carregando...</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00AF7D" text @click="closeDialog">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="dialogPrint" max-width="500" class="dialog-print" style="z-index: 99999;">
      <div class="div" id="printMe" v-if="selectedOrder != null" style="font-size: 10px">
        <small>VENDA DE MERCADORIA</small><br>
        <small>ORCAMENTO ATACADO Nº: {{ (selectedOrder.id + "").padStart(6, "0") }}</small><br>
        <small>Emissão: {{ new Date(selectedOrder.created_at).toLocaleDateString() }} &nbsp; Número: {{ (selectedOrder.id + "").padStart(6, "0") }}</small><br>
        <small>Vendedor: {{ selectedOrder.cliente.vendedor }}</small><br>
        <small>Cliente: {{ selectedOrder.cliente.nome }}</small><br>
        <small>Contato: {{ selectedOrder.cliente.whatsapp }}</small><br>
        <small>CPF/CNPJ: {{ selectedOrder.cliente.cpfcnpj }}</small><br>
        <table>
          <thead style="font-size: 12px">
            <tr>
              <th style="text-align: left;">Código</th>
              <th style="text-align: left;">Produto</th>
              <th style="text-align: left;">Q/T</th>
              <th style="text-align: left;">Unitário</th>
              <th style="text-align: left;">Total</th>
            </tr>
          </thead>
          <tbody style="font-size: 12px">
            <tr v-for="(p, index) in selectedOrder.produtos" :key="index">
              <td width="100px">{{ p.referencia }}.{{ (p.corWear + "").padStart(3, "0") }}</td>
              <td>{{ p.nome }}</td>
              <td width="50px">{{ p.estoquePedido }} {{ p.tamanho }}</td>
              <td width="100px">{{ p.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              <td width="100px">{{ (p.valor * p.estoquePedido).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
            </tr>
          <hr>
        </tbody>
          <tfoot>
            <tr>
              <td colspan="3">Qtde: {{ selectedOrder.produtos.reduce((acc, p) => acc + p.estoquePedido, 0) }}</td>
              <td style="text-align: right;">Total:</td>
              <td>{{ selectedOrder.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      
    </div>
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})


function generateImageUrl(image, userData) {
  return `https://storeimg.smartpdvstore.com/${userData.client_code}/${userData.brand_code}/${image}`;
}

export default {
  name: 'OrdersPage',
  metaInfo() {
    return {
      title: `Pedidos - ${config.name}`
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      headers: [
        {
          text: 'Venda/Itens',
          align: 'center',
          sortable: false,
          value: 'items',
        },
        { text: 'Data/Hora', value: 'date', align: 'center', },
        { text: 'Total', value: 'price', align: 'center', },
        { text: 'Cliente', value: 'costumer', align: 'center', },
        // { text: 'Status Entrega', value: 'shipping', align: 'center', },
        { text: 'Ações', value: 'actions', align: 'center', sortable: false },
      ],
      orders: [],
      userData: {},
      storeData: {},
      loadingPage: false,
      dialog: false,
      dialogPrint: false,
      selectedOrder: null
    }
  },
  async mounted() {
    await this.init()
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de pedidos`})
  },
  methods: {
    async init() {
      this.loadingPage = true
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      await this.getOrders()
      this.loadingPage = false
    },
    async getOrders() {
      try {
        const { data } = await api.get(`/orders`, await this.$store.getters.getConfig)
        if(data == null){
          this.orders = []
          return
        }
        this.orders = data
      } catch (error) {
        console.error(error)
      }
    },
    openDialog(item) {
      this.selectedOrder = item
      console.log(this.selectedOrder)
      this.dialog = true
    },
    openDialogToPrint(item) {
      this.selectedOrder = item
      this.dialogPrint = true
      setTimeout(() => {
        this.printMe()
      }, 200)
    },
    closeDialog() {
      this.dialog = false
      this.selectedOrder = null
    },
    generateImageUrl(image) {
      return generateImageUrl(image, this.$store.state.userData)
    },
    async printMe(){
      await this.$htmlToPaper('printMe');
    },
    async deleteOrder(id) {
      let c = confirm('Deseja realmente excluir este pedido?')
      if (!c) return
      try {
        await api.delete(`/orders/${id}`, await this.$store.getters.getConfig)
        this.getOrders()
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.column-pos {
  text-align: center;
  color: rgb(55 65 81);
  padding: .5rem;
}

.column-title {
  font-size: 1rem;
  font-weight: 400;
}

.column-subtitle {
  font-size: .8rem;
  font-weight: 400;
}

/* Estilo adicional para garantir espaçamento em telas móveis */
.dialog-spacing {
  margin: 16px !important;
}

@media (min-width: 600px) {
  .dialog-spacing {
    margin: 24px !important;
  }
}

.spanValue {
  text-align: right;
}

.order-info {
  margin-bottom: 20px;
}

.product-info {
  margin-bottom: 20px;
}

.order-info-item {
  padding: 8px 0;
  background-color: #fff;
}

.order-info-item:nth-child(even) {
  background-color: #f9f9f9;
}

.section-header {
  background-color: #00af7dba;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.totals {
  padding: 10px;
  background-color: #fff;
}

.total-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.total-item-alt {
  background-color: #f9f9f9;
}

.total-item span {
  display: inline-block;
  min-width: 100px;
}

.total-item-bold {
  font-weight: bold;
}

.estampa{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
    }
    
    .cor {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #00000049;
    }

.dialog-print{
  position: absolute;
  top: -10000000000px;
}
</style>
