<template>
  <v-app>
    <v-container fluid>
      <v-row v-if="isMobile()">
        <v-col>
          <img src="../assets/Logo Beeasy v1 - CInza SEM FUNDO.svg" class="logo" alt="">
        </v-col>
      </v-row>
      <v-row>
        <v-col class="image" v-if="!isMobile()"></v-col>
        <v-col class="login">
          <div class="card-login">
            <img src="../assets/Logo Beeasy v1 - CInza SEM FUNDO.svg" class="logo-desk" v-if="!isMobile()" alt="">
            <h1>Resetar Senha</h1>
            <p>Informe sua nova senha</p>
            <br>
            <v-form ref="form">
              <div class="form-item">
                <label>Nova Senha</label>
                <input type="password" class="input-default" placeholder="••••••••••" v-model="newPassword" required>
              </div>
              <div class="form-item">
                <label>Confirmar Senha</label>
                <input type="password" class="input-default" placeholder="••••••••••" v-model="confirmPassword"
                  required>
              </div>
            </v-form>
            <div class="form-item">
              <button class="button-default" @click="resetPassword">Alterar</button>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
  baseURL: config.baseURL
})

export default {
  name: 'ForgotPage',
  data() {
    return {
      newPassword: '',
      confirmPassword: ''
    }
  },
  methods: {
    isMobile() {
      return screen.width <= 760;
    },
    async resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.$toast.error('As senhas não coincidem')
        return
      }

      try {
        const token = this.$route.params.token
        await api.put(`/user/reset_password/${token}`, { password: this.newPassword })
        this.$toast.success('Senha alterada com sucesso')
        this.$router.push('/login')
      } catch (error) {
        this.$toast.error('Erro ao alterar a senha')
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
/* DEFAULT STYLES */
* {
  font-family: "Outfit", sans-serif;
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #2b3b54;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #2b3b54;
  margin-bottom: 1rem;
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #2b3b54;
  /* Substitua pelo código hexadecimal da cor do botão na imagem */
  border: 1px solid #2b3b54;
  /* Substitua pelo código hexadecimal da cor do botão na imagem */
  cursor: pointer;
}

.logo {
  width: 200px;
  margin: 0 auto;
  display: block;
  margin-top: 2rem;
}

.logo-desk {
  width: 200px;
  float: right;
  display: block;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.image {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-between;
  padding-left: 40px;
  background-position: 50%;
  background-image: url("../assets/loginbg.jpg");
}

.login .card-login {
  width: 80%;
  display: block;
  margin: 0 auto;
  margin-top: 2rem;
}

.login .form-item {
  margin-bottom: 1rem;
}

.login .form-item label {
  font-size: 1rem;
  font-weight: 500;
  color: #2b3b54;
}

.login .form-item button {
  margin-bottom: 5rem;
}

.error-data {
  color: red;
}
</style>
