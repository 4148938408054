<template>
    <v-app>
        <v-container fluid>
            <v-row>
                <v-col class="image" v-if="!isMobile()"></v-col>
                <v-col class="login">
                    <div class="card-login">
                        <h1>Login</h1>
                        <p>Informe seu acesso para entrar</p>
                        <br>
                        <div class="form-item">
                            <label>Email</label>
                            <input type="text" class="input-default" placeholder="teste@gmail.com" v-model="data.email">
                        </div>
                        <div class="form-item">
                            <label>Senha</label>
                            <input type="password" class="input-default" @keypress.enter="login"
                                placeholder="••••••••••" v-model="data.password">
                        </div>
                        <div class="forgot">
                            <a href="" @click.prevent="showForgotPasswordDialog">Esqueci minha senha</a>
                        </div>
                        <div class="error-data" v-if="error">
                            <p>Dados inválidos</p>
                        </div>
                        <div class="form-item">
                            <button class="button-default" @click="login" v-if="!loading">Entrar</button>
                            <button class="button-default" disabled v-if="loading">Aguarde...</button>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <!-- Forgot Password Dialog -->
            <v-dialog v-model="forgotPasswordDialog" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Recuperar Senha</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form">
                            <div class="form-item">
                                <label>Email</label>
                                <input type="email" class="input-default" v-model="forgotPasswordEmail" required>
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <button class="button-default" @click="sendForgotPasswordEmail"
                            :disabled="loading || !forgotPasswordEmail">
                            <template v-if="loading">
                                <loading />
                            </template>
                            <template v-else>
                                Enviar
                            </template>
                        </button>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'
import Loading from '@/components/Loading'

const api = axios.create({
    baseURL: config.baseURL
})

export default {
    name: 'HomePage',
    components: {
        Loading
    },
    metaInfo() {
        return {
            title: `Login - ${config.name}`
        }
    },
    data() {
        return {
            loading: false,
            error: false,
            config: {},
            data: {
                email: '',
                password: ''
            },
            forgotPasswordDialog: false,
            forgotPasswordEmail: ''
        }
    },
    methods: {
        isMobile() {
            return screen.width <= 760;
        },
        async login() {
            try {
                if (this.data.email == '' || this.data.password == '') {
                    this.$toast.warning('Preencha todos os campos')
                    return
                }
                this.loading = true;
                let response = await api.post('/login', this.data)
                this.config = {
                    headers: { Authorization: `Bearer ${response.data.token}` }
                }
                console.log(this.config)
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                this.$store.commit('update_dados', response.data.user)
                this.$toast.success('Login efetuado com sucesso')
                if(response.data.user.role == 'ADM') {
                    this.$router.push('/adm/users')
                } else {
                    this.$router.push('/orders')
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.error = true
            }
        },
        showForgotPasswordDialog() {
            this.forgotPasswordDialog = true;
        },
        async sendForgotPasswordEmail() {
            this.loading = true;
            try {
                await api.post('/user/forgot_password', { email: this.forgotPasswordEmail })
                this.$toast.success('Email de recuperação enviado com sucesso')
                this.forgotPasswordDialog = false;
            } catch (error) {
                this.$toast.error('Erro ao enviar email de recuperação')
                this.forgotPasswordEmail = ''  // Limpar o campo de email em caso de erro
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
/* DEFAULT STYLES */
* {
    font-family: "Outfit", sans-serif;
}

.input-default {
    width: 100%;
    padding: .5rem;
    color: #2b3b54;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid #2b3b54;
    margin-bottom: 1rem;
}

.button-default {
    width: 100%;
    padding: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    background-color: #2b3b54;
    /* Substitua pelo código hexadecimal da cor do botão na imagem */
    border: 1px solid #2b3b54;
    /* Substitua pelo código hexadecimal da cor do botão na imagem */
    cursor: pointer;
    text-align: center;
}

.logo {
    width: 200px;
    margin: 0 auto;
    display: block;
    margin-top: 2rem;
}

.logo-desk {
    width: 200px;
    float: right;
    display: block;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.image {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-between;
    padding-left: 40px;
    background-position: 50%;
    background-image: url("../assets/bg-dd.png");
}

.login .card-login {
    width: 80%;
    display: block;
    margin: 0 auto;
    margin-top: 2rem;
}

.login .form-item {
    margin-bottom: 1rem;
}

.login .form-item label {
    font-size: 1rem;
    font-weight: 500;
    color: #2b3b54;
}

.login .form-item button {
    margin-bottom: 5rem;
}

.login .forgot a {
    display: block;
    font-size: 16px;
    text-decoration: underline;
    font-weight: medium;
    color: #2b3b54;
    margin-bottom: 2rem;
}

.login .register p {
    font-size: 16px;
    font-weight: 400;
    color: #2b3b54;
}

.login .register p a {
    font-size: 16px;
    font-weight: 500;
    color: #2b3b54;
}

.error-data {
    color: red;
}

.fill {
    background-color: #2b3b54f1;
    z-index: 99999999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loading-modal img {
    display: block;
    margin: 0 auto;
    margin-top: 22vh;
    width: 20%;
}

@media (max-width: 768px) {
    .loading-modal img {
        display: block;
        margin: 0 auto;
        margin-top: 22vh;
        width: 50%;
    }
}
</style>
