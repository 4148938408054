<template>
    <div>
        <div class="sidebar" v-if="!$store.getters.isMobile">
            <div class="logo">
                <v-container fluid>
                    <v-row>
                        <v-col class="img">
                            <img src="../assets/logo-dd.png" alt="Beeasy Logo">
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <ul>
                <li :class="{ 'menu-item': true, active: m.active }" v-for="m in menu" :key="m.title" @click="goTo(m)"
                    :ref="m.name">
                    <v-container class="item">
                        <v-row>
                            <v-col cols="3" class="icon">
                                <img :src="require(`../assets/icons/sidebar/v2/${m.icon}`)" alt="">
                            </v-col>
                            <v-col class="title">
                                <span v-if="m.name === '---'">
                                    <v-badge color="green" content="Novo" inline>
                                        {{ m.title }}
                                    </v-badge>
                                </span>
                                <span v-else>{{ m.title }}</span>

                                <!-- <span>
                                    {{ m.title }}
                                </span> -->
                            </v-col>
                        </v-row>
                    </v-container>
                </li>
                <li class="menu-item bottom" @click="logout()">
                    <v-container class="item">
                        <v-row>
                            <v-col cols="3" class="icon">
                                <img src="../assets/icons/sidebar/v2/Saída.svg" alt="">
                            </v-col>
                            <v-col class="title">
                                <span>Encerrar sessão</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </li>
            </ul>
        </div>
        <div class="sidebar-mobile" v-if="$store.getters.isMobile">
            <v-container>
                <v-row justify-center>
                    <v-col></v-col>
                    <v-col cols="3">
                        <button
                            :class="{ 'button-menu': true, 'bt-active': menu.filter(m => m.name === active)[0].name == 'orders' }"
                            @click="goTo({ name: 'orders' })">
                            <img src="../assets/icons/sidebar/v2/Pedidos.svg" alt="">
                            Pedidos
                        </button>
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    data() {
        return {
            menu: [],
            userData: localStorage.user ? JSON.parse(localStorage.user) : null,
            toggleMenu: false,
            active: 'home'
        }
    },
    async mounted() {
        await this.init()
        await this.setMenu()
        await this.activeMenu()
    },
    methods: {
        async init() {
            if (localStorage.getItem('token')) {
                console.log('> Atualizando dados USUARIO no STORE')
                this.$store.commit('update_dados', JSON.parse(localStorage.getItem('user')))

                console.log('> Atualizando dados LOJA no STORE')
                this.$store.commit('update_store', JSON.parse(localStorage.getItem('store')))
            }
        },
        async setMenu() {
            if (this.userData.role === 'ADM') {
                this.menu = [
                    { name: 'adm/users', icon: 'Dashboard.svg', title: 'Usuários', active: false },
                ]
            } else {
                this.menu = [
                    { name: 'orders', icon: 'Pedidos.svg', title: 'Pedidos', active: false },
                ]
            }
        },
        async activeMenu() {
            this.menu.forEach(m => {
                if (m.name === this.$route.path.split('/')[1]) {
                    m.active = true
                    this.active = m.name
                } else {
                    m.active = false
                }
            })
        },
        goTo(e) {
            if (e.name === '') {
                return
            }
            if (this.$route.path != `/${e.name}`) {
                this.$router.push(`/${e.name}`)
            }
        },
        async logout() {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            if (this.$route.path != '/login') {
                this.$router.push('/login')
            }
        },
    },
    watch: {
        async $route() {
            await this.setMenu()
            await this.activeMenu()
        }
    }
}
</script>


<style scoped>
* {
    font-family: "Questrial", sans-serif;
}

/* Sidebar geral */
.sidebar {
    background-color: #2b3b54;
    color: #fff;
    height: 100%;
    max-width: 200px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
}

.sidebar .logo {
    cursor: pointer;
    filter: grayscale(100%) opacity(.7);
    transition: 600ms;
    margin-bottom: 1.4rem;
}

.sidebar .logo:hover {
    cursor: pointer;
    filter: grayscale(0%) opacity(1);
}

.sidebar .logo img {
    width: 80%;
    display: block;
    margin: 0 auto;
}

.sidebar .logo .text {
    padding: 0;
    padding-top: 2rem;
    padding-right: 1rem;
    color: rgb(255, 243, 1);
}

.sidebar .logo .img {
    padding-top: 1.7rem;
}

.sidebar .logo span {
    font-size: 30px;
    letter-spacing: .1ch;
    font-family: 'Exo';
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    line-height: 1;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    padding-left: 0 !important;
}

.sidebar .menu-item {
    color: #b6b6b6;
    transition: 600ms;
}

.sidebar .menu-item:hover {
    background: hsla(0, 0%, 100%, .05);
    cursor: pointer;
}

.sidebar .menu-item.bottom {
    position: absolute;
    bottom: 1.2rem;
    width: 100%;
    border-top: 1px solid hsla(0, 0%, 100%, .05);
}

.sidebar .menu-item.active {
    box-shadow: inset 2px 0 0 0 #00AF7D;
    background: hsla(0, 0%, 100%, .05);
}

.sidebar .menu-item .item img {
    width: 70%;
    float: right;
    aspect-ratio: 1 / 1;
    margin-top: .2rem;
    /* filter: grayscale(100%) opacity(.7); */
    filter: invert(1);
}

.sidebar .menu-item .item .title {
    padding: .5rem .1rem 0 0;
    text-align: left;
}

.sidebar .menu-item .item .title span {
    font-size: 14px;
}

/* Sidebar para dispositivos móveis */
.sidebar-mobile {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1001;
    background-color: #2b3b54;
    padding: 15px 7px 10px 7px;
    padding: 0 !important;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.sidebar-mobile ul {
    list-style: none !important;
    padding-left: 0 !important;
}

.sidebar-mobile ul li {
    display: inline;
    padding: 1rem;
}

.sidebar-mobile .menu-item img {
    width: 1rem;
    min-width: 1rem;
    aspect-ratio: 1 / 1;
    filter: invert(1);
}

.sidebar-mobile .menu-item {
    width: 1rem;
    min-width: 1rem;
    aspect-ratio: 1 / 1;
}

.sidebar-mobile .menu-item.active {
    box-shadow: inset 0px -5px 0px 0px #fff301;
    background: hsla(0, 0%, 100%, .05);
}

.sidebar-mobile .button-home {
    background-color: #00AF7D;
    border-radius: 10px;
    width: 30% !important;
    display: block;
    margin: 0 auto;
}

.sidebar-mobile .button-home img {
    width: 100%;
    filter: invert(1)
}


.sidebar-mobile .button-menu {
    width: 100%;
    color: #fff;
    text-align: center;
    display: block;
    margin: 0 auto;
    font-size: 10pt;
    padding: 5px;
}

.sidebar-mobile .button-menu img {
    width: 40%;
    filter: invert(1);
    display: block;
    margin: 0 auto;
    margin-bottom: 5px;
}

.sidebar-mobile .button-menu.bt-active {
    background-color: #00AF7D;
    border-radius: 10px;
}
</style>